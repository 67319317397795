import React from 'react';
import { Footer } from 'components/theme';
import { Global } from './styles';
import './fonts.css';
import 'bootstrap/dist/css/bootstrap.css';
import AppTheme from 'components/theme/AppTheme';

export const Layout: React.FC = ({ children }) => (
  <>
    <Global />
    <AppTheme>{children}</AppTheme>
    <Footer />
  </>
);
