import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Hamburger from './Hamburger';
import Sidebar from './Sidebar';
import { Wrapper, Overlay } from './styles';

interface HeaderProps {
  product: 'advertising' | 'giftcards' | 'menus';
}

export const Header: React.FC<HeaderProps> = props => {
  const [sidebar, toggle] = useState(false);
  const [top, setTop] = useState(true);

  useEffect(() => {
    const listener = (e: any) => {
      setTop(e.target.scrollingElement.scrollTop <= 0);
    };
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', listener);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', listener);
      }
    };
  }, []);

  return (
    <Wrapper top={top}>
      <Overlay sidebar={sidebar} onClick={() => toggle(!sidebar)} />
      <Navbar product={props.product} />
      <Hamburger sidebar={sidebar} toggle={toggle} />
      <Sidebar sidebar={sidebar} toggle={toggle} product={props.product} />
    </Wrapper>
  );
};
