import styled from 'styled-components';

export interface ContainerProps {
  noMobilePadding?: boolean;
  noYPadding?: boolean;
}

export const Container = styled.div<ContainerProps>`
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  padding: ${props => (props.noYPadding ? '0 2rem' : '2rem')};

  @media (max-width: 1280px) {
    max-width: 1000px;
  }

  @media (max-width: 600px) {
    padding: ${props => (props.noYPadding ? '0 1rem' : '1rem')};
  }

  ${props =>
    props.noMobilePadding &&
    `
  @media (max-width: 600px) {
    padding: 0;
  }`}
`;
