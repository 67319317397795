import React from 'react';
import { CSSTransition } from 'react-transition-group';
import './styles.css';

interface FaseTransitionProps {
  in?: boolean;
}

export const FadeTransition: React.FC<FaseTransitionProps> = props => {
  return (
    <CSSTransition
      {...props}
      classNames="fade"
      timeout={200}
      mountOnEnter={true}
      unmountOnExit={true}
    >
      {props.children}
    </CSSTransition>
  );
};
