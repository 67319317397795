import React, { Ref } from 'react';
import styled from 'styled-components';

interface InputProps {
  error: any;
  label?: string;
}

export const Input: React.FC<InputProps> = React.forwardRef(
  (props, ref: Ref<HTMLInputElement>) => (
    <>
      {props.label && <StyledLabel>{props.label}</StyledLabel>}
      <input
        ref={ref}
        css={
          `
          width: 100%;
          box-sizing: border-box;
          border: 2px solid #6c63ff;
          padding: 0.8rem 1rem;
          border-radius: 7px;
          margin-bottom: 0.5rem;
          transition: 0.3s;
          border-color: ${props.error ? '#ff4136' : '#6c63ff'};

          &::placeholder {
            color: #a7a7a7;
          }
        ` as any
        }
        {...props}
      />
    </>
  )
);

export const StyledLabel = styled.label`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 3px;
`;

export const StyledInput = styled.input<InputProps>``;
