import React from 'react';

export const RadioButton = ({
  field,
  id,
  label,
  value,
  selectedValue,
  className,
  onChange,
  ...props
}: any) => (
  <div>
    <input
      name={field.name}
      type="radio"
      id={id}
      value={value}
      checked={selectedValue === value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      {...props}
    />
    <label css={{ marginLeft: '0.5rem' }} htmlFor={id}>
      {label}
    </label>
  </div>
);
