import styled from 'styled-components';

export const Wrapper = styled.div<{ top: boolean }>`
  position: fixed;
  z-index: 10;
  background: rgba(255, 255, 255, 1);
  width: 100%;
  top: 0;
  box-shadow: ${props =>
    props.top ? undefined : `rgba(0, 0, 0, 0.09) 0 0 25px 0`};
  transition: all 0.2s ease-in;
`;

interface OverlayProps {
  sidebar: boolean;
}

export const Overlay = styled.div<OverlayProps>`
  position: fixed;
  background: rgba(255, 255, 255, 1);
  width: 100%;
  height: 100%;
  display: none;
  transition: 0.4s;

  ${({ sidebar }) =>
    sidebar &&
    `
			display: block;
			z-index: 4;
	`}
`;
