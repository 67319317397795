import styled from 'styled-components';

interface WrapperProps {
  desktop?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  a {
    color: #6d6d6d;
    text-decoration: none;
    &: hover {
      text-decoration: none;
    }
  }

  ${({ desktop }) =>
    desktop
      ? `
			@media (max-width: 960px) {
					display: none;
			}

			a {
					margin-right: 2rem;

					&:last-child {
							margin-right: unset;
					}
			}
		`
      : `
			padding: 3rem;
			display: flex;
			flex-direction: column;

			a {
					margin-bottom: 1rem;

					&:last-child {
							margin-bottom: unset;
					}
			}
	`}
`;

export const MauveHoverLink = styled.a`
  &:hover {
    color: #e088ff;
  }
`;
