import React, { useContext, useEffect, useState } from 'react';
import {
  Formik,
  Form,
  FastField,
  ErrorMessage,
  useFormikContext,
} from 'formik';
import * as Yup from 'yup';
import { Input } from 'components/common';
import {
  Error,
  Center,
  InputField,
  Button,
} from 'components/common/Form/styles';
import { SignUpFormContext } from './SignUpFormContext';
import FormProps from './FormProps';

const AccountForm: React.FC<FormProps> = props => {
  const formContext = useContext(SignUpFormContext);
  const [errorOnSubmit, setErrorOnSubmit] = useState(false);
  return (
    <Formik
      initialValues={{
        username: formContext.value.businessname
          ?.toLowerCase()
          .replace(/[^\w-]/g, ''),
        password: '',
        repeatPassword: '',
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().required('Username field is required'),
        password: Yup.string()
          .required('Password field is required')
          .matches(/.{8,}/, 'The password must be at least 8 characters long.')
          .matches(
            /.*[!@#$%^&*]/,
            'Password must contain at least one special character.'
          ),
        repeatPassword: Yup.string()
          .required('Please confirm the password.')
          .test('passwords-match', 'Passwords must match.', function(value) {
            return this.parent.password === value;
          }),
      })}
      onSubmit={async (
        { username, password },
        { setSubmitting, resetForm }
      ) => {
        const loginInfo = {
          username,
          password,
        };
        try {
          setSubmitting(true);
          setErrorOnSubmit(false);
          const response: Response = await formContext.createAccount(loginInfo);
          if (!response.ok) {
            // @ts-ignore
            throw new Error('failed');
          }
          if (typeof window !== 'undefined') {
            if (props.product === 'giftcards') {
              window.location.href = `${process.env.GATSBY_GIFTCARD_BASE_URL}`;
            } else {
              window.location.href = `${process.env.GATSBY_PORTAL_BASE_URL}/login`;
            }
          }
        } catch (err) {
          setSubmitting(false);
          setErrorOnSubmit(true);
        }
      }}
    >
      <AccountFormForm errorOnSubmit={errorOnSubmit} />
    </Formik>
  );
};

const AccountFormForm: React.FC<{ errorOnSubmit: boolean }> = props => {
  const formContext = useContext(SignUpFormContext);

  const formikContext = useFormikContext<{
    username: string;
    password: string;
    repeatPassword: string;
    success: boolean;
  }>();

  const usernameInput = React.createRef<HTMLInputElement>();
  const passwordInput = React.createRef<HTMLInputElement>();

  useEffect(() => {
    const { username } = formContext.value;
    const usernameInput = React.createRef<HTMLInputElement>();
    if (username) {
      passwordInput.current?.focus();
    } else {
      usernameInput.current?.focus();
    }
  }, []);

  return (
    <Form
      name="business-signup"
      method="post"
      onSubmit={formikContext.handleSubmit}
    >
      <InputField>
        <Input
          id="username"
          ref={usernameInput}
          label="Username"
          as={FastField}
          type="text"
          name="username"
          component="input"
          aria-label="username"
          placeholder="Username*"
          error={
            formikContext.touched.username && formikContext.errors.username
          }
          onChange={formikContext.handleChange}
          value={formikContext.values.username}
        />
        <ErrorMessage component={Error} name="username" />
      </InputField>
      <InputField>
        <Input
          id="password"
          ref={passwordInput}
          label="Password"
          aria-label="password"
          component="input"
          as={FastField}
          type="password"
          name="password"
          placeholder=""
          error={
            formikContext.touched.password && formikContext.errors.password
          }
          onChange={formikContext.handleChange}
          value={formikContext.values.password}
        />
        <ErrorMessage component={Error} name="password" />
      </InputField>
      <InputField>
        <Input
          as={FastField}
          label="Confirm Password"
          component="input"
          aria-label="confirm password"
          id="repeatPassword"
          type="password"
          name="repeatPassword"
          placeholder=""
          error={
            formikContext.touched.repeatPassword &&
            formikContext.errors.repeatPassword
          }
          onChange={formikContext.handleChange}
          value={formikContext.values.repeatPassword}
        />
        <ErrorMessage component={Error} name="repeatPassword" />
      </InputField>
      <Center>
        <Button
          css={{ minWidth: '200px' }}
          secondary
          type="submit"
          disabled={formikContext.isSubmitting}
        >
          {formContext.activeForm === 'demo'
            ? 'Request Demo'
            : 'Create Account'}
        </Button>
        {props.errorOnSubmit && (
          <div>
            <Error>An error occurred. Please try again.</Error>
          </div>
        )}
      </Center>
    </Form>
  );
};

export default AccountForm;
