import React, { useContext, useState } from 'react';
import { Form, FastField, ErrorMessage, Formik } from 'formik';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Recaptcha from 'react-google-recaptcha';
import * as Yup from 'yup';
import { Input } from 'components/common';
import {
  Error,
  Center,
  InputField,
  Button,
  RecaptchaField,
} from 'components/common/Form/styles';
import { SignUpFormContext } from './SignUpFormContext';
import FormProps from './FormProps';

interface BusinessFormProps extends FormProps {
  type: 'business' | 'demo';
}

const BusinessForm: React.FC<BusinessFormProps> = ({ type, product }) => {
  const formContext = useContext(SignUpFormContext);
  const [errorOnSubmit, setErrorOnSubmit] = useState(false);

  const handleChange = (prop: string, props: any) => (e: any) => {
    props.handleChange(e);
    formContext.updateValue(prop, e.target.value);
  };

  return (
    <Formik
      initialValues={{
        ...formContext.value,
        recaptcha: '',
      }}
      validationSchema={() => {
        let schema: any = {
          businessname: Yup.string().required(
            'Business name field is required'
          ),
          email: Yup.string()
            .email('Invalid email')
            .required('Email field is required'),
          phone: Yup.string()
            .required('Phone number field is required')
            .test('phone-valid', 'Phone number must be valid.', function(
              value
            ) {
              return (
                value && !!parsePhoneNumberFromString(value, 'US')?.isValid()
              );
            }),
          recaptcha: Yup.string()
            .required("Please verify you're not a robot!")
            .nullable(),
        };
        if (type === 'demo') {
          schema = {
            ...schema,
            name: Yup.string().required('Name field is required.'),
          };
        }
        return Yup.object().shape(schema);
      }}
      onSubmit={async ({ businessname, email, phone }, { setSubmitting }) => {
        try {
          setSubmitting(true);
          setErrorOnSubmit(false);
          if (formContext.activeForm === 'business') {
            await formContext.submitRecruit(product, {
              businessname,
              email,
              phone,
            });
          } else if (formContext.activeForm === 'demo') {
            await formContext.requestDemo({ businessname, email, phone });
          }
        } catch (err) {
          setSubmitting(false);
          setErrorOnSubmit(true);
        }
      }}
    >
      {props => (
        <Form
          name="business-signup"
          method="post"
          onSubmit={props.handleSubmit}
        >
          <InputField>
            <Input
              id="businessname"
              label="Business Name"
              as={FastField}
              type="text"
              name="businessname"
              component="input"
              aria-label="businessname"
              placeholder="Business name*"
              error={props.touched.businessname && props.errors.businessname}
              onChange={handleChange('businessname', props)}
              value={props.values.businessname}
            />
            <ErrorMessage component={Error} name="businessname" />
          </InputField>
          {type === 'demo' ? (
            <InputField>
              <Input
                id="name"
                label="Your Name"
                as={FastField}
                type="text"
                name="name"
                component="input"
                aria-label="name"
                placeholder="Your name*"
                error={props.touched.name && props.errors.name}
                onChange={handleChange('businessname', props)}
                value={props.values.name}
              />
              <ErrorMessage component={Error} name="name" />
            </InputField>
          ) : null}
          <InputField>
            <Input
              id="email"
              label="Email"
              aria-label="email"
              component="input"
              as={FastField}
              type="email"
              name="email"
              placeholder="Email*"
              error={props.touched.email && props.errors.email}
              onChange={handleChange('email', props)}
              value={props.values.email}
            />
            <ErrorMessage component={Error} name="email" />
          </InputField>
          <InputField>
            <Input
              as={FastField}
              label="Phone Number"
              component="input"
              aria-label="phone number"
              id="phone"
              type="tel"
              name="phone"
              placeholder="Phone number*"
              error={props.touched.phone && props.errors.phone}
              onChange={handleChange('phone', props)}
              onBlur={(e: any) => {
                props.handleBlur(e);
                const { value } = e.target;
                if (!props.errors.phone && value.trim()) {
                  const pn = parsePhoneNumberFromString(value, 'US');
                  if (pn?.isValid()) {
                    formContext.updateValue('phone', value);
                    props.setFieldValue('phone', pn.formatNational());
                  }
                }
              }}
              value={props.values.phone}
            />
            <ErrorMessage component={Error} name="phone" />
          </InputField>
          {props.values.businessname &&
            props.values.email &&
            props.values.phone && (
              <RecaptchaField>
                <FastField
                  component={Recaptcha}
                  sitekey={process.env.GATSBY_RECAPTCHA_KEY}
                  name="recaptcha"
                  onChange={(value: any) =>
                    props.setFieldValue('recaptcha', value)
                  }
                />
                <ErrorMessage component={Error} name="recaptcha" />
              </RecaptchaField>
            )}
          <Center>
            <Button
              css={{ minWidth: '200px' }}
              secondary
              type="submit"
              disabled={props.isSubmitting}
            >
              {type === 'demo' ? 'Request Demo' : 'Create Account'}
            </Button>
            {errorOnSubmit && (
              <div>
                <Error>An error occurred. Please try again.</Error>
              </div>
            )}
          </Center>
        </Form>
      )}
    </Formik>
  );
};

export default BusinessForm;
