import React from 'react';
import { Wrapper, MauveHoverLink } from './styles';
import { Link } from 'gatsby';

interface NavbarLinksProps {
  desktop?: boolean;
  product: 'advertising' | 'giftcards';
}

const getProductLinks = (currentProduct: string) => {
  if (currentProduct === "giftcards") {
    return (
      <>
      <Link to="/">Advertising</Link>
      <Link to="/menus">Menus</Link>
      </>
    )
  } else if (currentProduct === "advertising") {
    return (
      <>
      <Link to="/giftcards">Gift Cards</Link>
      <Link to="/menus">Menus</Link>
      </>
    )
  } else if (currentProduct === "menus" ) {
      return (
      <>
      <Link to="/">Advertising</Link>
      <Link to="/giftcards">Gift Cards</Link>
      </>
    )
  }
}


const NavbarLinks: React.FC<NavbarLinksProps> = (props) => {
  const pathname =
    typeof window !== 'undefined' ? window.location.pathname : '';
  const isHome =
    (props.product === 'advertising' &&
      (pathname === '' || pathname === '/')) ||
    (props.product === 'giftcards' && pathname.startsWith('/giftcards'));
  const signInLink =
    props.product === 'giftcards'
      ? `${process.env.GATSBY_GIFTCARD_BASE_URL}/login`
      : `${process.env.GATSBY_PORTAL_BASE_URL}/login`;
  return (
    <Wrapper desktop={props.desktop}>
      {!props.desktop && !isHome && (
        <Link to={props.product === 'advertising' ? '/' : '/giftcards/'}>
          Home
        </Link>
      )}
      {props.product === 'advertising' && !pathname.startsWith('/drivers') && (
        <Link to="/drivers/">Drivers</Link>
      )}
      {!pathname.startsWith('/about') && <Link to="/about/">About</Link>}
      {!pathname.startsWith('/blog') && <Link to="/blog/">Blog</Link>}

        {getProductLinks(props.product)}
      {/* {props.product === 'advertising' ? (
        <Link to="/giftcards/">Gift Cards</Link>
      ) : (
        <Link to="/">Advertising</Link>
      )} */}
      <MauveHoverLink href={signInLink}>
        <b>Sign In</b>
      </MauveHoverLink>
    </Wrapper>
  );
};

export default NavbarLinks;
