import styled from 'styled-components';
import { Button as CommonButton } from 'components/common/Button';
import Image from 'gatsby-image';

export const Error = styled.span`
  color: #ff4136;
  font-size: 1rem;
`;

export const Center = styled.div`
  text-align: center;

  h4 {
    font-weight: normal;
  }
`;

export const InputField = styled.div`
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const RecaptchaField = styled(InputField)`
  & > div {
    margin: 0 auto;
  }
`;

export const Button = styled(CommonButton)`
  width: 60%;
  margin: 0 auto;
  background-color: #0c59a6;
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  @media (max-width: 560px) {
    position: relative;
    height: 10rem;
  }
`;

export const Background = styled(Image)`
  width: 100%;
  height: auto;
`;

export const IntroWrapper = styled.div`
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }

  @media (max-width: 450px) {
    padding: 2rem 0.5rem;
  }
`;

export const Details = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 1rem;
  width: 50%;
  max-width: 34rem;
  z-index: 1;
  transition: height 200ms ease-in;

  @media (max-width: 960px) {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 2rem;
    padding: 1.5rem 1rem;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 36pt;
    color: #212121;

    @media (max-width: 680px) {
      font-size: 30pt;
    }
  }

  h4 {
    margin-bottom: 2.5rem;
    font-weight: normal;
  }
`;

export const Thumbnail = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
  }

  img {
    width: 100%;
  }
`;

export const SwitchFormTypeLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  margin: 0 auto;
`;

export const SignInText = styled.p`
  margin: 1rem 0 0 0;
`;

export const SignInLink = styled.a`
  text-decoration: underline;
`;
