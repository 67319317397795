import React from 'react';
import { useFormikContext, ErrorMessage } from 'formik';
import { Error } from 'components/common/Form/styles';

export const RadioButtonGroup = ({
  label,
  name,
  children,
}: { [key: string]: any } & { name: string }) => {
  const formikContext = useFormikContext();
  return (
    <div>
      <fieldset>
        <label>
          <b>{label}</b>
        </label>
        {children}
        {(formikContext.errors as any)[name] &&
        (formikContext.touched as any)[name] ? (
          <ErrorMessage component={Error} name={name} />
        ) : null}
      </fieldset>
    </div>
  );
};
