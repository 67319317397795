import React from 'react';
import NavbarLinks from '../NavbarLinks';
import { Wrapper } from './styles';

interface SidebarProps {
  sidebar: any;
  toggle: any;
  product: 'advertising' | 'giftcards';
}

const Sidebar: React.FC<SidebarProps> = ({ sidebar, toggle, product }) => (
  <Wrapper active={sidebar} onClick={toggle}>
    <NavbarLinks product={product} />
  </Wrapper>
);

export default Sidebar;
