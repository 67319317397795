import styled from 'styled-components';
import { Link } from 'gatsby';

export const Wrapper = styled.div`
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    color: #212121;
    &:hover {
      color: #000;
    }
  }
`;

export const LogoLink = styled(Link)`
  display: flex;
`;

export const Logo = styled.img`
  height: 2rem;
  margin: 0;
`;
