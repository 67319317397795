module.exports = {
  defaultTitle: 'Paneau - Advertise in Ubers and Lyfts',
  giftCardTitle: 'Paneau - Create Online Gift Cards',
  menuTitle: 'Paneau - Create Digital Menus',
  logo: 'https://www.paneau.io/favicon/favicon-512.png',
  author: 'Paneau 2020',
  url: 'https://www.paneau.io',
  legalName: 'Paneau',
  defaultDescription:
    'Paneau is an advertising platform that puts tablets inside Uber & Lyft vehicles.',
  giftCardDescription:
    'Paneau provides a platform to create and sell online gift cards.',
  menuDescription:
    'Use Paneau to create digital menus for your dine in guests',
  socialLinks: {
    facebook: 'https://www.facebook.com/itspaneau/',
    linkedin: 'https://www.linkedin.com/company/paneau/',
    instagram: 'https://www.instagram.com/itspaneau/',
    twitter: 'https://twitter.com/itspaneau',
  },
  social: {
    facebook: 'itspaneau',
    twitter: '@itspaneau',
  },
  googleAnalyticsID: 'UA-160307884-1',
  themeColor: '#e088ff',
  backgroundColor: '#ffffff',
  address: {
    city: 'San Francisco',
    region: 'California',
    country: 'USA',
    zipCode: '94133',
  },
  contact: {
    email: 'team@paneau.io',
  },
  foundingDate: '2018',
};
