import React from 'react';
import styled from 'styled-components';
import headerIllustration from 'assets/illustrations/header.svg';

export const HeaderBackground = () => <Background />;

export const Background = styled.div`
  background-image: url(${headerIllustration});
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 30rem;
  z-index: -1;
`;
