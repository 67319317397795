import React from 'react';
import { Container } from 'components/common';
import { Wrapper, Flex, Links, Details } from './styles';
import social from './social.json';

export const Footer = () => (
  <Wrapper>
    <Flex as={Container}>
      <Details>
        <span>
          <p>© {new Date().getFullYear()}. All rights are reserved.</p>
          <p>
            Made with{' '}
            <span aria-label="love" role="img">
              ❤️
            </span>{' '}
            by Paneau from North Beach, SF to North County, SD
          </p>
          <p>
            Contact us at <a href="mailto:hello@paneau.io.">hello@paneau.io</a>
          </p>
        </span>
      </Details>
      <Links>
        {social.map(({ id, name, link, icon }) => (
          <a
            key={id}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`follow me on ${name}`}
          >
            <img width="24" src={icon} alt={name} />
          </a>
        ))}
      </Links>
    </Flex>
  </Wrapper>
);
