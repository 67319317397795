import { useContext } from 'react';
import { SignUpFormContext } from './SignUpFormContext';
import { SwitchTransition } from 'react-transition-group';
import { FadeTransition, SuccessPage } from '..';
import BusinessForm from './BusinessForm';
import AccountForm from './AccountForm';
import FormProps from './FormProps';
import React from 'react';

export const Form: React.FC<FormProps> = props => {
  const { activeForm } = useContext(SignUpFormContext);
  return (
    <SwitchTransition>
      <FadeTransition key={activeForm}>
        {activeForm === 'business' || activeForm === 'demo' ? (
          <BusinessForm
            key="business"
            type={activeForm}
            product={props.product}
          />
        ) : activeForm === 'demosuccess' ? (
          <SuccessPage>
            <h3>
              Thank you for your interest!
              <br />
              Someone from our team will reach out shortly.
            </h3>
          </SuccessPage>
        ) : (
          <AccountForm key="account" product={props.product} />
        )}
      </FadeTransition>
    </SwitchTransition>
  );
};
