import React from 'react';
import './styles.css';

export const NotificationBanner: React.FC = () => (
  <div className="hourly-banner banner-close">
    <div className="hourly-banner-content">
      <div className="hourly-banner-text banner-close">
        Local business impacted by COVID-19? We're offering{' '}
        <a href="/giftcards">this</a> for free.
      </div>
    </div>
  </div>
);
