import React from 'react';
import logo from 'assets/logo/logo.svg';
import { Container } from 'components/common';
import NavbarLinks from '../NavbarLinks';
import { Wrapper, Logo, LogoLink } from './styles';

interface NavbarProps {
  product: 'advertising' | 'giftcards';
}

const Navbar: React.FC<NavbarProps> = props => {
  return (
    <Wrapper as={Container}>
      <LogoLink to={props.product === 'advertising' ? '/' : '/giftcards'}>
        <Logo src={logo} alt="Paneau - Rideshare Advertising" />
      </LogoLink>
      <NavbarLinks product={props.product} desktop />
    </Wrapper>
  );
};

export default Navbar;
