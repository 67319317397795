import styled from 'styled-components';

interface VerticalDividerProps {
  height: string;
}

export const VerticalDivider = styled.div<VerticalDividerProps>`
  height: ${({ height }) => height};
  width: 1px;
  background-color: #e4e4e4;
  align-self: center;
  margin: 0 1rem;
`;
