import React from 'react';
import Success from 'assets/icons/success.inline.svg';
import styled from 'styled-components';

export const SuccessPage: React.FC<{ showIcon?: boolean }> = props => (
  <Wrapper>
    {props.children}
    {props.showIcon !== false && <Success width="150px" height="150px" />}
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 400px;
  align-items: center;
  text-align: center;
  padding-bottom: 2rem;
`;
