import React from 'react';
import { Wrapper, Bar } from './styles';

interface HamburgerProps {
  sidebar: boolean;
  toggle: (tg: any) => void;
}

const Hamburger: React.FC<HamburgerProps> = ({ sidebar, toggle }) => (
  <Wrapper sidebar={sidebar} onClick={() => toggle(!sidebar)}>
    <Bar top sidebar={sidebar} />
    <Bar mid sidebar={sidebar} />
    <Bar bottom sidebar={sidebar} />
  </Wrapper>
);

export default Hamburger;
